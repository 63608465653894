.upload-file-container {
  position: relative;
  input.form-control-file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
}

.upload-img-container {
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px dashed transparent;
  cursor: pointer;
  input.form-control-file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
}

.upload-img-btn {
  margin: 0 !important;
  height: 100%;
  width: 100%;
  border: 1px dashed #adb5bd;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  color: #6c757d;
}

.upload-img-preview {
  height: 100%;
  width: 104px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.upload-img-container_selected:hover {
  border-color: red;
  padding: 8px;
}

.image-item {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}

.file-remove-btn {
  display: none;
}

.file-item:hover,
.image-item:hover {
  color: red;
}

.file-item:hover > .file-remove-btn,
.image-item:hover > div > .file-remove-btn {
  color: red;
  display: block;
  cursor: pointer;
}

.participiant-remove-btn {
  cursor: pointer;
  margin-left: 5px;
  color: #dc3545;
}

.more-apps-btn {
  margin-top: -10px;
  text-decoration: underline;
  cursor: pointer;
}

.event-logo,
.event-logo-details {
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eef4f6;
  border-radius: 2px;
}

.event-logo {
  width: 81px;
  height: 81px;
}

.event-logo-details {
  width: 136px;
  height: 136px;
}

.sort-option {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #495057;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
