a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}
.collapse {
  .nav {
    align-items: end;
    flex-direction: column;
    letter-spacing: 0.04em;
    font-size: 16px;
    text-transform: uppercase;
  }
}

/* .nav-item {
  a:not(.btn) {
    padding-right: 0 !important;
  }    
} */

.nav-toggler-cross {
  color: red;
  .navbar-toggler-icon {
    background-image: url('/cross-icon.svg') !important;
    background-size: 70% !important;
  }
}

.navbar-collapse {
  .btn-outline-primary {
    margin-top: 16px;
    text-transform: uppercase;
  }
}

.navbar-collapse ul li a {
  text-align: end !important;
}

.btn-lg,
.btn-group-lg > .btn {
  font-size: 16px !important;
}

.mobile-modal {
  .modal-header {
    border-bottom: none;
    padding-right: 12px;
    button.close {
      background-color: transparent;
      border: none;
      line-height: 0.1;
      font-size: 40px;
      color: white;
    }
  }
  .modal-content {
    background-color: transparent;
    border: none;
  }
  .modal-body {
    background-color: white;
    border-radius: 8px;
    margin-top: 20px;
  }
}

.mobile-navbar-actions {
  margin-top: 100px !important;
  .btn-primary:active {
    border-color: #086a98;
    color: #086a98 !important;
    background-color: white;
  }
  .btn-outline-primary:active,
  .btn-outline-primary:hover {
    border-color: #086a98;
    color: white !important;
    background-color: #086a98;
  }
}

.modal-dialog.mobile-modal {
  height: calc(100% - 37px);
}

.modal-body {
  ul {
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    line-height: 120%;
    li {
      width: 100%;
      a {
        text-align: center;
      }
    }
  }
  .btn-lg,
  .btn-group-lg > .btn {
    margin: 12px 0;
  }
}

.modal-content {
  height: 100%;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.dropdown-menu-center {
  right: auto !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, 40) !important;
  -o-transform: translate(-50%, 40) !important;
  transform: translate(-50%, 40px) !important;
}

@media (min-width: 576px) {
  .nav {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }

  .navbar-collapse .btn-outline-primary {
    margin-top: 0;
  }
  .d-grid.gap-2.w-100.nav-item {
    margin-right: 16px;
  }
  .collapse.navbar-collapse {
    justify-content: flex-end;
  }
}
