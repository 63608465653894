.form-select {
  position: relative;
  color: #6c757d !important;
  cursor: pointer;
  margin-bottom: 20px;
}

.form-select-options-container {
  position: absolute;
  z-index: 10;
  left: 0;
  background-image: none !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.form-select-focus {
  border-color: #84b5cc !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(8 106 152 / 25%);
}

.app-option {
  padding: 5px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  .option-icon {
    margin-right: 10px;
  }
  .option-counter-control {
    display: inline-block;
    cursor: pointer;
    margin-left: 22px;
  }
}
.app-option-selected {
  background-color: #086a98;
  color: #fff;
}
.app-option:hover {
  background-color: #a2ccdf;
}