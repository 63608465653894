.login-nav-tab {
  border-bottom: none !important;
  .nav-link {
    text-decoration: underline;
    text-underline-offset: 2px;
    margin-bottom: -2px !important;
  }
  .nav-item > .active.nav-link {
    text-decoration: none;
    background-color: #eef4f6;
    border: none;
    margin-bottom: -2px !important;
  }
}
.tab-content {
  padding: 0.5rem 1rem;
  background-color: #eef4f6;
}
